<template>
  <div class="customInput">
    <div :class="{flags:flags}" class="selectorContent">
      <Selector
        :border="false"
        :disable="disabled"
        :flags="flags"
        :initBandera="initBandera"
        :initCode="initCode"
        :options="options"
        :required="required"
        :start="initTypeDocument ? initTypeDocument : options[0].value"
        @change="changedSelector"
        v-if="selector"
        :indexFlag="indexFlag"
      />
      <div style="font-size: 14px;" class="code-country-custom-input" v-if="codeCountry">
        (+{{this.selectorValue !== ' ' ? this.selectorValue: '57'}})
      </div>
    </div>
    <div style="position: relative;" v-if="codeCountry">
      <i class="icon-cellphone-checkout"></i>
    </div>
    <input
      :style="`${ codeCountry ? 'padding-left:15px':''}`"
      :disabled="disabled"
      :placeholder="placeHolder"
      :required="required"
      :type="type"
      :maxlength="typeProvider === 'payU' ? 10 : 20"
      ref="input"
      v-model="inputValue"
      @blur="handleBlur"
    />
  </div>
</template>
<script>
import Selector from '@/components/Select/GeneralSelector.vue';
export default {
  components: {
    Selector
  },

  props: {
    initBandera: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: ''
    },
    // recibe las opciones con sus respectivos valores EJEMPLO: [
    //     { name: 'nombre1', value: 'valor1' },
    //     { name: 'nombre2', value: 'valor2' },
    //   ]

    options: {
      type: Array,
      default: () => [{}]
    },
    start: {
      type: String,
      default: ''
    },
    // si se desea que el selector este en alguna opcion predeterminada se debe colocar el value de dicha opcion
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    // si se quiere un selector en en lado izquierdo
    selector: {
      type: Boolean,
      default: false
    },
    // si se quiere un selector de numero para pais
    selectorCountries: {
      type: Boolean,
      default: false
    },
    valueInput: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    flags: {
      type: Boolean,
      default: false
    },
    initCode: {
      type: Number,
      default: 57
    },
    codeCountry: {
      type: Boolean,
      default: false
    },
    indexFlag: {
      type: Boolean,
      default: false
    },
    initTypeDocument: {
      type: String,
      default: null
    },
    typeProvider: {
      type: String,
      default: 'mp'
    }
  },
  watch: {
    inputValue () {
      this.$emit('valueChange', { selectorValue: this.selectorValue, inputValue: this.inputValue, country: this.countryValue });
    },
    selectorValue () {
      this.$emit('valueChange', { selectorValue: this.selectorValue, inputValue: this.inputValue, country: this.countryValue });
    },
    start () {
      if (this.start) this.selectorValue = this.start;
    },
    valueInput () {
      this.inputValue = this.valueInput;
    }
  },
  data () {
    return {
      selectorValue: ' ',
      countryValue: '',
      inputValue: '',
      BanderaPais: []
    };
  },
  mounted () {
    if (this.start) {
      this.selectorValue = this.start;
    }
    this.validate();
  },
  methods: {
    handleBlur () {
      this.$emit('blurInput');
    },
    changedSelector (e) {
      if (!this.indexFlag) {
        this.selectorValue = e;
      } else {
        this.selectorValue = e.value;
        this.countryValue = e.country;
      }
    },
    validate (e = { target: this.$refs.input }) {
      const input = e.target;
      if (this.required && this.selectorValue.length < 1) {
        input.setCustomValidity(
          'Debes rellenar este campo'
        );
      } else {
        input.setCustomValidity(
          ''
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.customInput {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 6px;
  width: 100%;
  position: relative;
}
.icon-cellphone-checkout{
  background-color: var(--bgColorCheckout);
}
.selectorContent {
  max-width: 70px;
  width: 30%;
  height: 100%;
  position: relative;
  left: 2px;
  padding-right: 1px;
  position: relative;
  min-width: 50px;
  &::after {
    display: block;
    content: " ";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 7px;
    background-color: var(--colorCheckout);
  }
}
.flags {
  width: 18%;
}
input {
  font-size: 12px;
  border-radius: 5px;
  outline: unset;
  min-height: 30px;
  text-indent: 8px;
  width: 65%;
  border: unset;
  outline: none;
}
</style>
